import React from "react";
import { useNavigate } from "react-router-dom";

function CyberThreat() {
  const navigate = useNavigate();

  return (
    <div className="tw-bg-[#FCF6E5] tw-p-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-lg tw-font-bold">
          Cyber Threat Intelligence Feed
        </h2>
        <a
          href="#"
          className="tw-text-sm tw-text-gray-500"
          onClick={() => navigate("/ransomware-feed")}
        >
          Show All
        </a>
      </div>
      <div className="tw-grid md:tw-grid-cols-3 tw-gap-4">
        <div className="tw-bg-[#FAF8E5] tw-p-4 tw-rounded-md">
          <h3 className="tw-font-semibold tw-text-base tw-text-[#455A64]">Ransomware News</h3>
          <p className="tw-text-[#455A64] tw-text-xs tw-text-[#455A64] tw-text-xs">23-07-2024</p>
          <p className="tw-text-[#455A64] tw-text-sm">
            Major Hospital Chain Hit by Ransomware Attack, Patient Data at Risk
          </p>
        </div>
        <div className="tw-bg-[#FAF8E5] tw-p-4 tw-rounded-md">
          <h3 className="tw-font-semibold tw-text-base tw-text-[#455A64]">Cyber Security News</h3>
          <p className="tw-text-[#455A64] tw-text-xs tw-text-[#455A64] tw-text-xs">23-07-2024</p>
          <p className="tw-text-[#455A64] tw-text-sm">
            Company Refuses to Pay Ransom, Successfully Recovers Data from
            Backups
          </p>
        </div>
        <div className="tw-bg-[#FAF8E5] tw-p-4 tw-rounded-md">
          <h3 className="tw-font-semibold tw-text-base tw-text-[#455A64]">Cyber Security News</h3>
          <p className="tw-text-[#455A64] tw-text-xs tw-text-[#455A64] tw-text-xs">23-07-2024</p>
          <p className="tw-text-[#455A64] tw-text-sm">
            Company Refuses to Pay Ransom, Successfully Recovers Data from
            Backups
          </p>
        </div>
      </div>
    </div>
  );
}

export default CyberThreat;
