import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from 'common/components/Button/Button';
import InputGroup from 'common/components/InputGroup/InputGroup';
import ExportButton from 'common/components/Export/ExportButton';
import CreationDropdown from 'common/components/DropDown/CreationDropdown';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';

import {
  search as searchDataLeaks,
  SEARCH_TYPE,
} from 'store/slices/search/manual/dataLeaks';
import { kaduuHighlightTags, formatFileSize } from 'utils/global';

const Leaks = ({ selectedOptionLabel, searchTerm, onLeakDetail }) => {
  const [currentView, setCurrentView] = useState(SEARCH_TYPE.SIMPLE);
  const [selectedLeakId, setLeakId] = useState(null);

  const { simple, extended } = useSelector(
    (state) => state.search.manual.dataLeaks,
  );

  const simpleExport = useExport({
    source: 'leak_distinct',
    sort: simple.sort,
    size: simple.size,
    searchTerm,
  });

  const extendedExport = useExport({
    source: 'leak',
    sort: extended.sort,
    length: extended.length,
    size: extended.size,
    searchTerm,
  });

  const switchView = (type) => {
    setCurrentView(type);
  };

  const dispatch = useDispatch();

  const setCurrentPage = (page) => {
    dispatch(
      searchDataLeaks({
        type:
          currentView == SEARCH_TYPE.SIMPLE
            ? SEARCH_TYPE.SIMPLE
            : SEARCH_TYPE.EXTENDED,
        query:
          currentView == SEARCH_TYPE.SIMPLE
            ? searchTerm
            : selectedLeakId
              ? `${searchTerm} AND leakId:${selectedLeakId}`
              : searchTerm,
        page: page - 1,
      }),
    );
  };

  const handleResultDetail = (leakId) => {
    setLeakId(leakId);

    dispatch(
      searchDataLeaks({
        type: SEARCH_TYPE.EXTENDED,
        page: 0,
        query: `${searchTerm} AND leakId:${leakId}`,
      }),
    );

    setCurrentView(SEARCH_TYPE.EXTENDED);
  };

  const simpleColumns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: (value) => (
        <span className="tw-whitespace-nowrap tw-text-[#44464A]">{value}</span>
      ),
    },
    {
      Header: 'Leak Name',
      accessor: 'leakName',
      render: (value, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span
              className="tw-text-[#00B6FF] tw-cursor-pointer"
              onClick={() => {
                if (onLeakDetail) onLeakDetail(record.id);
              }}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Size',
      accessor: 'leakSize',
      render: (value) => (
        <span className="tw-whitespace-nowrap tw-text-[#44464A]">
          {formatFileSize(value)}
        </span>
      ),
    },
    {
      Header: 'Tags',
      accessor: 'leakTags',
      render: (value) => {
        return (
          <div className="tw-flex tw-gap-1 tw-break-all">
            {value.split(',').map((keyword, index) => {
              return (
                <span
                  className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                  key={index}
                >
                  {keyword}
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: 'Results',
      accessor: 'resultCount',
      render: (value, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span
              className="tw-whitespace-nowrap tw-text-[#00B6FF] tw-cursor-pointer"
              onClick={() => handleResultDetail(record.id)}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Risk',
      accessor: 'cvssScore',
      render: (value) => {
        if (value <= 2.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <span className="tw-whitespace-nowrap tw-bg-green-50 tw-border tw-border-green-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-green-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 5.0) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <span className="tw-whitespace-nowrap tw-bg-amber-50 tw-border tw-border-amber-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-amber-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 7.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <span className="tw-whitespace-nowrap tw-bg-red-50 tw-border tw-border-red-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-red-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <span className="tw-whitespace-nowrap tw-bg-red-600 tw-border tw-border-red-700 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-white">
                {value.toFixed(1)}
              </span>
            </div>
          );
        }
      },
    },
  ];

  const extendedColumns = [
    {
      Header: 'Leak Name',
      accessor: 'leakName',
      render: (value, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span
              className="tw-whitespace-nowrap tw-text-[#00B6FF] tw-cursor-pointer"
              onClick={() => {
                if (onLeakDetail) onLeakDetail(record.leakId);
              }}
            >
              {value}
            </span>
            <span>{record.createdAt}</span>
          </div>
        );
      },
    },
    {
      Header: 'Leak Content Extract',
      accessor: 'content',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-gap-1 ">
              {record.leakTags.split(',').map((keyword, index) => {
                return (
                  <span
                    className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                    key={index}
                  >
                    {keyword}
                  </span>
                );
              })}
            </div>
            <p
              className="tw-max-w-[270px] tw-mt-[10px]"
              dangerouslySetInnerHTML={{
                __html: kaduuHighlightTags(record.content),
              }}
            />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.fileName}</p>
          </>
        );
      },
    },
    {
      Header: 'Leak Date',
      accessor: 'leakDiscoverDate',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
  ];

  const handleChange = (_length) => {
    if (
      (currentView == SEARCH_TYPE.SIMPLE ? simple.length : extended.length) !==
      _length
    ) {
      dispatch(
        searchDataLeaks({
          type: currentView,
          query:
            currentView == SEARCH_TYPE.SIMPLE
              ? searchTerm
              : selectedLeakId
                ? `${searchTerm} AND leakId:${selectedLeakId}`
                : searchTerm,
          length: _length,
        }),
      );
    }
  };

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-items-center tw-justify-between">
          <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
            {selectedOptionLabel} Search Results
          </span>
          <div className="tw-flex">
            <CustomButton
              bgColor={
                currentView == SEARCH_TYPE.SIMPLE
                  ? 'tw-bg-primary'
                  : 'tw-bg-[#F5F6F8]'
              }
              textColor={
                currentView == SEARCH_TYPE.SIMPLE
                  ? 'tw-text-white'
                  : 'tw-text-dark'
              }
              border={
                currentView == SEARCH_TYPE.SIMPLE
                  ? 'tw-border-none'
                  : 'tw-border tw-border-[#D9D9D9]'
              }
              text="Leak Overview"
              className={
                'tw-h-[40px] tw-p-3 tw-whitespace-nowrap !tw-rounded-[8px_0px_0px_8px]'
              }
              onClick={() => switchView(SEARCH_TYPE.SIMPLE)}
            />
            <CustomButton
              bgColor={
                currentView == SEARCH_TYPE.EXTENDED
                  ? 'tw-bg-primary'
                  : 'tw-bg-[#F5F6F8]'
              }
              textColor={
                currentView == SEARCH_TYPE.EXTENDED
                  ? 'tw-text-white'
                  : 'tw-text-dark'
              }
              border={
                currentView == SEARCH_TYPE.EXTENDED
                  ? 'tw-border-none'
                  : 'tw-border tw-border-[#D9D9D9]'
              }
              text="Result Details"
              className={
                'tw-h-[40px] tw-p-3 tw-whitespace-nowrap !tw-rounded-[0px_8px_8px_0px] '
              }
              onClick={() => switchView(SEARCH_TYPE.EXTENDED)}
            />
          </div>
        </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4 tw-justify-end">
          {currentView == SEARCH_TYPE.EXTENDED && (
            <CreationDropdown
              defaultValue={extended.length}
              onChange={handleChange}
            />
          )}

          <ExportButton
            onChange={
              currentView == SEARCH_TYPE.SIMPLE
                ? simpleExport.handleExportOptionChange
                : extendedExport.handleExportOptionChange
            }
            isLoading={
              currentView == SEARCH_TYPE.SIMPLE
                ? simpleExport.exporting
                : extendedExport.exporting
            }
            disabled={
              !(currentView == SEARCH_TYPE.SIMPLE
                ? simpleExport.canExport
                : extendedExport.canExport)
            }
          />
        </div>
        <div className="tw-flex tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-gap-6">
          <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">
              {currentView == SEARCH_TYPE.SIMPLE
                ? simple.totalElements
                : extended.totalElements}
            </span>
          </div>
          <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <div className="tw-relative">
          <Table
            data={
              currentView == SEARCH_TYPE.SIMPLE ? simple.list : extended.list
            }
            columns={
              currentView == SEARCH_TYPE.SIMPLE
                ? simpleColumns
                : extendedColumns
            }
            selectable={true}
            onRowSelection={
              currentView == SEARCH_TYPE.SIMPLE
                ? simpleExport.handleRowSelection
                : extendedExport.handleRowSelection
            }
          />
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
            <div
              className={
                (
                  currentView == SEARCH_TYPE.SIMPLE
                    ? simple.loading
                    : extended.loading
                )
                  ? 'tw-invisible'
                  : 'tw-visible'
              }
            >
              <p className="tw-my-4">
                Showing leaks:{' '}
                {(currentView == SEARCH_TYPE.SIMPLE
                  ? simple.totalElements
                  : extended.totalElements) === 0
                  ? 0
                  : ((currentView == SEARCH_TYPE.SIMPLE
                      ? simple.currentPage
                      : extended.currentPage) -
                      1) *
                      (currentView == SEARCH_TYPE.SIMPLE
                        ? simple.size
                        : extended.size) +
                    1}{' '}
                —{' '}
                {(currentView == SEARCH_TYPE.SIMPLE
                  ? simple.currentPage
                  : extended.currentPage) *
                  (currentView == SEARCH_TYPE.SIMPLE
                    ? simple.size
                    : extended.size) >
                (currentView == SEARCH_TYPE.SIMPLE
                  ? simple.totalElements
                  : extended.totalElements)
                  ? currentView == SEARCH_TYPE.SIMPLE
                    ? simple.totalElements
                    : extended.totalElements
                  : (currentView == SEARCH_TYPE.SIMPLE
                      ? simple.currentPage
                      : extended.currentPage) *
                    (currentView == SEARCH_TYPE.SIMPLE
                      ? simple.size
                      : extended.size)}{' '}
                of{' '}
                {currentView == SEARCH_TYPE.SIMPLE
                  ? simple.totalElements
                  : extended.totalElements}
              </p>
            </div>

            {(currentView == SEARCH_TYPE.SIMPLE
              ? simple.totalPages
              : extended.totalPages) > 1 && (
              <Paginator
                currentPage={
                  currentView == SEARCH_TYPE.SIMPLE
                    ? simple.currentPage
                    : extended.currentPage
                }
                totalPages={
                  currentView == SEARCH_TYPE.SIMPLE
                    ? simple.totalPages
                    : extended.totalPages
                }
                onPageChange={setCurrentPage}
              />
            )}
          </div>
          {(currentView == SEARCH_TYPE.SIMPLE
            ? simple.loading
            : extended.loading) && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaks;
