import React, { useState } from "react";
import {
  ArrowDown,
  ArrowDownLight,
  ChartButton,
  ChartLine,
  InfoIcon,
  Setting,
} from "../../../common/Icons";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import ReactApexChart from "react-apexcharts";
import CustomTable from "../../common/table/table.component";
import { Tooltip } from "react-tooltip";

function Leak() {
  const [view, setView] = useState("chart");
  const [open, setOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("30");

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    fill: {
      type: "solid",
      colors: ["#ECC551"],
    },
    colors: ["#ECC551"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const columns = [
    { Header: "Created", accessor: "created" },
    { Header: "Name", accessor: "name" },
    { Header: "Size", accessor: "size" },
    { Header: "Records", accessor: "records" },
  ];

  const data = [
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
  ];

  const chartSeries = [
    {
      name: "Risk Score",
      data: [40, 60, 75, 60, 80, 95, 85],
    },
  ];

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg">
      <div className="tw-flex  tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-1">
          <h2 className="tw-text-sm lg:tw-text-base tw-font-bold tw-whitespace-nowrap">
            {view === "chart" ? "Leak Trend Statistics" : "Latest Leaks"}
          </h2>
          <div className="my-anchor-elementsa23">
            <InfoIcon />
          </div>
          <Tooltip
            anchorSelect=".my-anchor-elementsa23"
            place="top"
            style={{ backgroundColor: "white", color: "black", width: "550px" }}
          >
            The Leak Trend Analysis provides a real-time overview of the most
            recent data leaks discovered and documented by our analysts. You can
            access this information through a graphical view for visual insights
            or a list view for detailed data representation, allowing you to
            monitor emerging threats and assess their relevance to your
            organization
          </Tooltip>
        </div>
        <div className="tw-flex tw-w-full tw-justify-end tw-items-end tw-gap-2">
          <div onClick={() => setView("chart")}>
            {/* Render ChartLine with condition based on `view` */}
            <ChartLine selected={view === "chart"} />
          </div>
          <div onClick={() => setView("table")}>
            {/* Render ChartButton with condition based on `view` */}
            <ChartButton selected={view === "table"} />
          </div>
        </div>
      </div>
      {view === "chart" && (
        <>
          <div className="tw-flex tw-items-center tw-gap-1">
            <span className="tw-text-[#263238] tw-text-base">
              <button
                className="tw-relative tw-flex tw-items-center"
                onClick={() => setOpen((prev) => !prev)}
              >
                Last {selectedDuration} days{" "}
                <span className="tw-ml-2">
                  <ArrowDownLight />
                </span>
                {open && (
                  <div className="tw-absolute tw-w-36 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 tw-right-0 tw-top-6">
                    <ul className="tw-text-left">
                    <li
                        className="tw-px-4 tw-pt-1 tw-text-[#44464A] tw-font-semibold tw-text-base "
                        // onClick={() => setSelectedDuration("30")}
                      >
                        Date Range
                      </li>
                      <li
                        className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                        onClick={() => setSelectedDuration("30")}
                      >
                        Last 30 Days
                      </li>
                      <li
                        className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                        onClick={() => setSelectedDuration("6")}
                      >
                        Last 6 Months
                      </li>
                      <li
                        className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                        onClick={() => setSelectedDuration("12")}
                      >
                        Last 12 Months
                      </li>
                    </ul>
                  </div>
                )}
              </button>
            </span>
          </div>

          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={330}
          />
        </>
      )}
      {view === "table" && (
        <div className="tw-mt-12">
          <CustomTable data={data} columns={columns} selectable={false} />
        </div>
      )}
    </div>
  );
}

export default Leak;
const options = ["Date Range", "Last Year", "Last 6 Month", "All Time"];
