import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import InputGroup from 'common/components/InputGroup/InputGroup';
import { DatabaseSearchOptions } from 'common/components/SearchComponent/data';
import { SearchIcon } from 'common/Icons';

const SearchComponent = ({
  defaultOption = {},
  searchTerm = '',
  onOptionChange,
  onSearchTermChange,
  onSearch,
  loading,
}) => {
  const [option, setOption] = useState({});
  const [searchInputValue, setSearchInputValue] = useState(searchTerm);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    setSearchInputValue(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (onSearchTermChange) onSearchTermChange(searchInputValue);
  }, [searchInputValue]);

  const handleSearch = async () => {
    Yup.object()
      .shape({
        searchInputValue: option.validation,
      })
      .validate({ searchInputValue })
      .then(() => {
        setErrorMessage('');
        onSearch(searchInputValue);
      })
      .catch((err) => {
        console.error(err.errors);
        setErrorMessage(err.errors);
      });
  };

  return (
    <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex lg:tw-flex-row tw-flex-col tw-rounded-lg tw-gap-3">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-1 md:tw-gap-0 tw-bg-[#F5F6F8] ">
          <div className="tw-w-full md:tw-max-w-[220px]">
            <CustomDropdown
              defaultOption={defaultOption}
              placeholder="Select"
              options={DatabaseSearchOptions}
              name="host"
              background={true}
              border={true}
              className="tw-w-full tw-h-[45px] tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-[8px_0px_0px_8px]"
              onChange={(option) => {
                setOption(option);
                {
                  onOptionChange && onOptionChange(option);
                }
              }}
            />
          </div>

          <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-px-4 tw-gap-3 md:tw-gap-[10px] tw-border-[0.5px] ">
            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                placeholder={`${
                  _.isEmpty(option)
                    ? 'Keyword...'
                    : `Enter your ${option.label}: e.g. ${option.placeholder}`
                }`}
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                className="!tw-bg-gray-100 tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 !tw-border-none"
              />
              {errorMessage && (
                <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <CustomButton
            text="Search"
            disabled={_.isEmpty(option) || loading}
            onClick={handleSearch}
            isLoading={loading}
            icon={<SearchIcon fill="#ffffff" />}
            className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
