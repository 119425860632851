import React from "react";
import CustomTable from "../../common/table/table.component";
import Heading from "../../../common/components/heading/Heading";

function Credientials() {
  const columns = [
    { Header: "Leaked Credentials", accessor: "created" },
    { Header: "Results", accessor: "results" },
  ];

  const data = [
    {
      created: "Total Number of Leaked Credentials",
      results: "111111",
    },
    {
      created: "Credentials originating from Stealer Logs*",
      results: "111111",
    },
    {
      created: "Credentials found in Combo Lists",
      results: "111111",
    },
  ];
  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Leaked Credentials" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default Credientials;
