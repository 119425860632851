import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/dataLeaks/search',
  async (
    { page, size, sort, length, query, progress = true },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState();

    if (_.isNil(page)) page = state.search.database.dataLeaks.currentPage - 1;
    if (_.isNil(size)) size = state.search.database.dataLeaks.size;
    if (_.isNil(sort)) sort = state.search.database.dataLeaks.sort;
    if (_.isNil(length)) length = state.search.database.dataLeaks.length;

    let progressValue = 15,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 75) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      if (progress) requestAnimationFrame(mockProgress);

      dispatch(setParams({ size, sort, length }));
      const response = await api.get('service/leak_extended_database_search/', {
        params: {
          page,
          size,
          sort,
          length,
          highlight: true,
          query,
        },
      });
      step = 1;

      return response.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const dataLeaksSlice = createSlice({
  name: 'dataLeaks',
  initialState: {
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    length: 500,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
      state.length = action.payload.length;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;

        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setProgress, setParams } = dataLeaksSlice.actions;

export default dataLeaksSlice.reducer;
