import React, { useRef, useState } from "react";
import { EyeIcon, Setting } from "../../../common/Icons";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import ExportButton from "../../../common/components/Export/ExportButton";
import Heading from "../../../common/components/heading/Heading";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import CustomTable from "../../common/table/table.component";
import DomainSelector from "./DomainSelector";

function Risk() {
  const [arePasswordsVisible, setArePasswordsVisible] = useState(false);
  const toggleAllPasswordsVisibility = () => {
    setArePasswordsVisible((prev) => !prev);
  };
  const columns = [
    { Header: "Created", accessor: "created" },
    {
      Header: "Login",
      accessor: "login",
      render: (_, record) => {
        return <span className="tw-text-blue">{record.login}</span>;
      },
    },
    {
      Header: (
        <div className="tw-flex tw-items-center tw-gap-[5px]">
          Password
          <button onClick={toggleAllPasswordsVisibility}>
            <EyeIcon
              color="#5B5D63"
              className="eye-icon-hover cursor-pointer"
            />
          </button>
        </div>
      ),
      accessor: "password",
      Cell: ({ row }) => {
        const { password } = row.original; // Destructure to get the password from the record
        return (
          <div className="tw-flex tw-items-center tw-gap-[10px]">
            {/* Show password if visibility is toggled on, otherwise asterisks */}
            <span>{arePasswordsVisible ? "111" : "******"}</span>
          </div>
        );
      },
    },
    { Header: "Leak Source", accessor: "leakSource" },
    { Header: "Website", accessor: "website" },
    {
      Header: "Status",
      accessor: "state",
      render: (_, record, index) => {
        return (
          <div className="tw-relative">
            <span
              onClick={() => toggleStatusDropdown(index)}
              className={`tw-cursor-pointer tw-whitespace-nowrap tw-rounded-full tw-py-[2px] tw-px-2 ${
                record.state === "Closed"
                  ? "tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-text-[#B93815]"
                  : "tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-text-[#067647]"
              }`}
            >
              {record.state}
            </span>
            {openDropdownIndex === index && (
              <div
                ref={statusDropdownRef}
                className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-white tw-rounded-lg tw-p-4 tw-divide-y tw-shadow-xl tw-z-10"
              >
                <h3 className="tw-text-black tw-font-semibold">
                  Change Status
                </h3>
                <ul className="tw-flex tw-flex-col tw-mt-3">
                  <li
                    className="tw-cursor-pointer tw-py-1"
                    onClick={() => handleStatusChange(index, "Closed")}
                  >
                    <span className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]">
                      Closed
                    </span>
                  </li>
                  <li
                    className="tw-cursor-pointer tw-py-1"
                    onClick={() => handleStatusChange(index, "Open")}
                  >
                    <span className="tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]">
                      Open
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  const [data, setData] = useState([
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "133111" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "111111" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "123232" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "1443111" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "1143311" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
    {
      created: "2024-06-17",
      login: "babar@thrivedx.com",
      password: arePasswordsVisible ? "1134311" : "***",

      leakSource: "Stealer Logs",
      website: "https://thrivedx.com/login",
      state: "Closed",
    },
  ]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Create refs for dropdown elements
  const settingsDropdownRef = useRef(null);
  const exportDropdownRef = useRef(null);

  // Use the useOutsideClick hook for both dropdowns
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  useOutsideClick(exportDropdownRef, () => setExportDropdown(false));

  const toggleDropdown = (event) => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text=" Leak Risk Analysis" />
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
        <Heading size="h3" text="Latest Account Breaches" />

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-4 tw-relative">
          <ExportButton />
          <div
            onClick={toggleDropdown}
            className="tw-cursor-pointer tw-relative"
          >
            <Setting />
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                onClick={(e) => e.stopPropagation()}
                className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <DomainSelector setDropdownOpen={setDropdownOpen} />
              </div>
            )}
          </div>

          <GenericDropdown
            options={options}
            defaultText="This Week"
            staticFirstOption={true}
          />
        </div>
      </div>
      <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
        <CustomTable data={data} columns={columns} selectable={false} />
      </div>
    </div>
  );
}

export default Risk;
const options = ["Date Range", "Last Year", "Last 6 Month", "All Time"];
