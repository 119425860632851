import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';

import { getResult } from 'store/slices/search/database/emailReferences';

const EmailRef = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    list: emailRefs,
    sort,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.emailReferences);

  const setCurrentPage = (page) => {
    dispatch(
      getResult({
        page: page - 1,
      }),
    );
  };

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value ?? 'N/A'}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: defaultRenderer,
    },
    {
      Header: 'Mail',
      accessor: 'mail',
      render: defaultRenderer,
    },
    {
      Header: 'Name',
      accessor: 'name',
      render: defaultRenderer,
    },
    {
      Header: 'Source',
      accessor: 'source',
      render: defaultRenderer,
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'mail_external', sort, size, length, searchTerm });

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>
          <ExportButton
            onChange={handleExportOptionChange}
            isLoading={exporting}
            disabled={!canExport}
          />
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
          <p>
            Showing emails:{' '}
            {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
            {currentPage * size > totalElements
              ? totalElements
              : currentPage * size}{' '}
            of {totalElements}
          </p>
        </div>

        <div className="tw-relative">
          <Table
            data={emailRefs}
            columns={columns}
            selectable={true}
            onRowSelection={handleRowSelection}
          />
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailRef;
