import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';

import { search as searchDataPastes } from 'store/slices/search/database/dataPastes';
import { kaduuHighlightTags } from 'utils/global';

const DataPastes = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    list: dataPastes,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.dataPastes);

  const setCurrentPage = (page) => {
    dispatch(
      searchDataPastes({
        query: searchTerm,
        page: page - 1,
        progress: false,
      }),
    );
  };

  // const handleChange = (_length) => {
  //   if (length !== _length) {
  //     dispatch(
  //       searchDataPastes({
  //         query: searchTerm,
  //         length: _length,
  //         progress: false,
  //       }),
  //     );
  //   }
  // };

  const columns = [
    {
      Header: <div className="tw-whitespace-nowrap">Paste Title</div>,
      accessor: 'title',
      render: (value, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-whitespace-nowrap tw-text-[#00B6FF]">
              {value}
            </span>
            <span>{record.createdAt}</span>
          </div>
        );
      },
    },
    {
      Header: 'Paste Content Extract',
      accessor: 'text',
      render: (value, record) => {
        return (
          <>
            <p
              className="tw-mt-[10px]"
              dangerouslySetInnerHTML={{
                __html: kaduuHighlightTags(value),
              }}
            />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.url}</p>
          </>
        );
      },
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'paste', size, searchTerm });

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
          Search Result for {selectedOptionLabel} Search
        </span>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
          <div className={loading ? 'tw-invisible' : 'tw-visible'}>
            <p>
              Showing pastes:{' '}
              {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
              {currentPage * size > totalElements
                ? totalElements
                : currentPage * size}{' '}
              of {totalElements}
            </p>
          </div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4">
            <ExportButton
              onChange={handleExportOptionChange}
              isLoading={exporting}
              disabled={!canExport}
            />
          </div>
        </div>
        <div className="tw-relative">
          <Table
            data={dataPastes}
            columns={columns}
            selectable={true}
            onRowSelection={handleRowSelection}
          />
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataPastes;
