import React from 'react';
import { useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table/table.component';

const PhishingAttack = ({ selectedOptionLabel, searchTerm }) => {
  const { list, totalElements } = useSelector(
    (state) => state.search.database.phishingAttack,
  );

  const size = 10;

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Link',
      accessor: 'link',
      render: defaultRenderer,
    },
    {
      Header: 'Verified',
      accessor: 'verified',
      render: defaultRenderer,
    },
    {
      Header: 'Online',
      accessor: 'online',
      render: defaultRenderer,
    },
    {
      Header: 'Target/Type',
      accessor: 'target',
      render: defaultRenderer,
    },
    {
      Header: 'Source',
      accessor: 'source',
      render: defaultRenderer,
    },
  ];

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>
          <ExportButton />
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <Table
          data={list}
          columns={columns}
          paginatorLabel="phishings"
          rowsPerPage={size}
        />
      </div>
    </div>
  );
};

export default PhishingAttack;
