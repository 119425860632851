import React from "react";
import CustomTable from "../../common/table/table.component";
import Heading from "../../../common/components/heading/Heading";

function TopRisky() {
  const columns = [
    { Header: "Top 3 Risky Users", accessor: "created" },
    { Header: "Results", accessor: "results" },
  ];

  const data = [
    {
      created: "babar@thrivedx.com",
      results: "111111",
    },
    {
      created: "babar@thrivedx.com",
      results: "111111",
    },
    {
      created: "babar@thrivedx.com",
      results: "111111",
    },
    {
        created: "babar@thrivedx.com",
        results: "111111",
      },
      {
        created: "babar@thrivedx.com",
        results: "111111",
      },
      {
        created: "babar@thrivedx.com",
        results: "111111",
      },
      {
        created: "babar@thrivedx.com",
        results: "111111",
      },
  ];
  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Top Risky Users" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default TopRisky;
