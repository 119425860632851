import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ProgressBar from 'common/components/ProgressBar/ProgressBar';
import { DatabaseSearchOptions } from 'common/components/SearchComponent/data';

import { searchActions } from 'store/actions';

import { search as searchDataLeaks } from 'store/slices/search/database/dataLeaks';
import { search as searchStealerLogs } from 'store/slices/search/database/stealerLogs';
import { search as searchURLShortener } from 'store/slices/search/database/urlShortener';
import { search as searchCloudStorage } from 'store/slices/search/database/cloudStorage';
import { search as searchDataPastes } from 'store/slices/search/database/dataPastes';
import { search as searchRansomware } from 'store/slices/search/database/ransomware';
import { search as searchEmailReferences } from 'store/slices/search/database/emailReferences';
import { search as searchDataBrokers } from 'store/slices/search/database/dataBrokers';
import { search as searchPhishingAttack } from 'store/slices/search/database/phishingAttack';
import { search as searchFakeApps } from 'store/slices/search/database/fakeApps';
import { search as searchSSLLogs } from 'store/slices/search/database/sslLogs';
import { search as searchCreditCard } from 'store/slices/search/database/creditCard';
import { search as searchCreditCardLeaks } from 'store/slices/search/database/creditCardLeaks';

import { convertToWildcardIP, removeLastIP } from 'utils/global';

import SearchComponent from './SearchComponent';
import SearchResult from './SearchResult';

const DatabaseSearch = () => {
  const dispatch = useDispatch();
  const licenseInfo = useSelector((state) => state.user.license);
  const database = useSelector((state) => state.search.database);

  const features = [
    {
      id: 1,
      premium: false,
      label: 'Data Leaks',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Leak & Credential Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id) &&
          (selectedOption.label === 'Credit Card'
            ? searchTerm && /^\d{8,}$/.test(searchTerm)
            : true)
        );
      },
      get store() {
        return selectedOption && selectedOption.label === 'Credit Card'
          ? database.creditCardLeaks
          : database.dataLeaks;
      },
      get searchFunc() {
        return selectedOption.label === 'Credit Card'
          ? searchCreditCardLeaks
          : searchDataLeaks;
      },
      progress: 0,
      except: [],
    },
    {
      id: 2,
      premium: false,
      label: 'Stealer Logs',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Stealer & Botnet Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.stealerLogs,
      searchFunc: searchStealerLogs,
      progress: 0,
      except: [2, 4, 5, 6, 7, 8],
    },
    {
      id: 3,
      premium: false,
      label: 'URL Shorteners',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'URL Shortener Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.urlShortener,
      searchFunc: searchURLShortener,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7, 8],
    },
    {
      id: 4,
      premium: false,
      label: 'Open Cloud Storage',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Open Cloud Container Exposure Detection',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.cloudStorage,
      searchFunc: searchCloudStorage,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7],
    },
    {
      id: 5,
      premium: true,
      label: 'Data Pastes',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Paste Site Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.dataPastes,
      searchFunc: searchDataPastes,
      progress: 0,
      except: [5],
    },
    {
      id: 6,
      premium: true,
      label: 'Threat Actor Publications',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Ransomware Tracking',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.ransomware,
      searchFunc: searchRansomware,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      id: 7,
      premium: true,
      label: 'Data Brokers',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'PII (Personally Identifiable Information) Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.dataBrokers,
      searchFunc: searchDataBrokers,
      progress: 0,
      except: [5, 7, 8],
    },
    {
      id: 8,
      premium: true,
      label: 'Phishing Attacks',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Phishtank Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.phishingAttack,
      searchFunc: searchPhishingAttack,
      progress: 0,
      except: [2, 3, 4, 5, 7, 8, 9],
    },
    {
      id: 9,
      premium: true,
      label: 'Fake Apps',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'App Store Spoofing Detection',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.fakeApps,
      searchFunc: searchFakeApps,
      progress: 0,
      except: [2, 3, 4, 5, 7, 8, 9],
    },
    {
      id: 10,
      premium: true,
      label: 'Credit Card Dumps',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Stolen Credit Card Tracking',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.creditCard,
      searchFunc: searchCreditCard,
      progress: 0,
      except: [1, 2, 4, 6, 7, 8, 9],
    },
    {
      id: 11,
      premium: true,
      label: 'Email References in the Surface Web',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'Email Reference Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.emailReferences,
      searchFunc: searchEmailReferences,
      progress: 0,
      except: [1, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      id: 12,
      premium: true,
      label: 'SSL Logs',
      subLabel: 'Free public leaks, published on hacker forums, Telegram & co',
      licenseTitle: 'SSL Logs',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          !this.except.includes(selectedOption.id)
        );
      },
      store: database.sslLogs,
      searchFunc: searchSSLLogs,
      progress: 0,
      except: [4, 5, 7, 8, 9],
    },
  ];

  const [autoSearch, setAutoSearch] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    // Parse the params
    const type = searchParams.get('type');
    const query = searchParams.get('query');
    if (type && query) {
      setSelectedOption(
        DatabaseSearchOptions.find((option) => option.id == type),
      );
      setSearchTerm(query);

      setAutoSearch(true);
    }

    dispatch(searchActions.resetProgress());
    setShowResult(false);
  }, []);

  useEffect(() => {
    if (autoSearch) {
      handleSearch(searchTerm);
    }
  }, [autoSearch]);

  useEffect(() => {
    if (searching) {
      let totalResult = 0;
      let finished = true;

      features.forEach((item) => {
        if (item.active && item.store) {
          totalResult += item.store.totalElements;
          finished &= !item.store.loading;
        }
      });

      setTotalResult(totalResult);
      if (finished) {
        setSearching(false);
        setShowResult(true);
      }
    }
  }, [database]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSearch = (query) => {
    setSearchTerm(query);

    setTotalResult(0);
    setSearching(true);

    // dispatch(searchDataLeaks({ page: 0, query }));
    // dispatch(searchURLShortener({ page: 0, query: query.split('.')[0] }));

    features.forEach((feature) => {
      let modifiedQuery = query;

      if (feature.active) {
        if (feature.searchFunc) {
          // Query modification
          if (selectedOption.label === 'Domain') {
            if (feature.id === 3 || feature.id === 4) {
              modifiedQuery = query.split('.')[0];
            }
          } else if (selectedOption.label === 'Name') {
            const name = query.toLowerCase().split(' ');
            const nameVariation = [
              `${name[0]} ${name[1]}`,
              `${name[0]}.${name[1]}`,
              `${name[0]}_${name[1]}`,
              `${name[0]}-${name[1]}`,
              `${name[0]}${name[1]}`,
            ];

            if (feature.id === 3 || feature.id === 4) {
              modifiedQuery = nameVariation[2];
            } else {
              modifiedQuery = nameVariation.join(' OR ');
            }
          } else if (selectedOption.label === 'IP Address & Range') {
            if (feature.id === 1 || feature.id === 5) {
              modifiedQuery = convertToWildcardIP(query);
            } else if (feature.id === 2) {
              modifiedQuery = removeLastIP(query);
            }
          }

          // Search
          dispatch(feature.searchFunc({ page: 0, query: modifiedQuery }));
        }
      }
    });

    // setSearching(false);
    // setShowResult(true);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-p-2">
      <h1 className="tw-text-xl tw-font-bold tw-leading-8 tw-text-left">
        Search Across All Database Sources
      </h1>
      {!showResult ? (
        <>
          <SearchComponent
            defaultOption={selectedOption}
            searchTerm={searchTerm}
            onSearchTermChange={setSearchTerm}
            onOptionChange={handleOptionChange}
            onSearch={handleSearch}
            loading={searching}
          />
          <div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6">
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  Total Results
                </div>
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  {/* {totalResult} */}
                </div>
              </div>
              <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                {features
                  .filter((item) => !item.premium)
                  .map((item, index) => (
                    <div key={index} className="tw-flex tw-flex-col tw-mb-6">
                      <ProgressBar
                        premium={item.premium}
                        label={item.label}
                        disabled={!item.active}
                        height="tw-h-[6px]"
                        subLabel={item.subLabel}
                        current={item.store ? item.store.progress : 0}
                        backgroundColor={
                          item.store
                            ? item.store.error
                              ? '#dc2626'
                              : '#ECC551'
                            : '#ECC551'
                        }
                        trackColor="tw-bg-[#EFF0F2]"
                        className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                        subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex">
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  Only Premium
                </div>
              </div>
              <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                {features
                  .filter((item) => item.premium)
                  .map((item, index) => (
                    <div key={index} className="tw-flex tw-flex-col tw-mb-6">
                      <ProgressBar
                        premium={item.premium}
                        label={item.label}
                        errorLabel={item.errorLabel}
                        disabled={!item.active}
                        height="tw-h-[6px]"
                        subLabel={item.subLabel}
                        current={item.store ? item.store.progress : 0}
                        backgroundColor={
                          item.store
                            ? item.store.error
                              ? '#dc2626'
                              : '#ECC551'
                            : '#ECC551'
                        }
                        trackColor="tw-bg-[#EFF0F2]"
                        className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                        subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SearchResult
          setSearch={setShowResult}
          selectedOption={selectedOption}
          searchTerm={searchTerm}
          tabsInfo={features}
        />
      )}
    </div>
  );
};

export default DatabaseSearch;
