import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import CustomButton from 'common/components/Button/Button';
import CloudDropdown from 'common/components/Export/CloudDropdown';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { Export } from 'common/Icons';
import Table from 'components/common/table/table.component';

import { parseURL, truncateString } from 'utils/global';

const OpenCloud = ({ selectedOptionLabel, searchTerm }) => {
  const [isDropdownOpenSetting, setDropdownOpenSetting] = useState(false);
  const settingsDropdownRefSetting = useRef(null);

  useOutsideClick(settingsDropdownRefSetting, () =>
    setDropdownOpenSetting(false),
  );

  const toggleDropdownSetting = (event) => {
    event.stopPropagation();
    setDropdownOpenSetting((prev) => !prev);
  };

  const { list, totalElements } = useSelector(
    (state) => state.search.database.cloudStorage,
  );

  const size = 10;
  const columns = [
    {
      Header: 'Search Team',
      accessor: 'term',
      render: () => {
        return <span className="tw-text-[#44464A]">{searchTerm}</span>;
      },
    },
    {
      Header: 'Bucket',
      accessor: 'bucket',
      render: (_, record) => {
        const bucket = parseURL(record.link).hostname;
        return <span className="tw-text-[#44464A]">{bucket}</span>;
      },
    },
    {
      Header: 'URL',
      accessor: 'link',
      render: (value, _, rowIndex) => {
        const short = truncateString(value, 255);

        return (
          <>
            <span
              className="tw-text-[#44464A] tw-break-all"
              id={`row-${rowIndex}`}
            >
              {short}
            </span>
            {short.length < value.length && (
              <Tooltip
                anchorSelect={`#row-${rowIndex}`}
                place="top-end"
                border="1px solid #eee"
                opacity={1}
                style={{
                  backgroundColor: '#ECC551',
                  color: 'black',
                  borderRadius: 10,
                  padding: 20,
                  width: 450,
                  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                <span className="tw-block tw-break-all">{value}</span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'last_modified',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A]">
            {value ? moment(value).format('YYYY-MM-DD') : 'N/A'}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>
          <div className="tw-flex tw-items-center tw-gap-2 tw-relative">
            <div className="tw-cursor-pointer">
              <CustomButton
                type="button"
                text="Export"
                className="tw-border-[#DFE0E3] tw-gap-2 tw-bg-black tw-text-white tw-border tw-flex tw-items-center tw-py-2 tw-px-[14px]"
                textColor="tw-text-[#44464A]"
                borderRadius="tw-rounded-lg"
                fontSize="tw-text-sm"
                fontWeight="tw-font-medium"
                icon={<Export />}
                onClick={toggleDropdownSetting}
              />
            </div>
            {isDropdownOpenSetting && (
              <div
                ref={settingsDropdownRefSetting}
                className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <CloudDropdown onClose={() => setDropdownOpenSetting(false)} />
              </div>
            )}
          </div>
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>
        <Table
          data={list}
          columns={columns}
          paginatorLabel="links"
          rowsPerPage={size}
        />
      </div>
    </div>
  );
};

export default OpenCloud;
