import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { useState } from "react";
import countryOne from '../../../common/Icons/CountryOne.svg'
import countryTwo from '../../../common/Icons/CountryTwo.svg'
import countryThree from '../../../common/Icons/CountryThree.svg'
import countryFour from '../../../common/Icons/CountryFour.svg'
import countrySix from '../../../common/Icons/CountrySix.svg'
import countryFive from '../../../common/Icons/CountryFive.svg'


// Mock country data with flags as paths to icons you mentioned
const countries = [
  { name: "Afghanistan", dialCode: "+93", flag: countryOne },
  { name: "Albania", dialCode: "+355", flag: countryTwo},
  { name: "Algeria", dialCode: "+213", flag: countryThree},
  { name: "American Samoa", dialCode: "+1-684", flag: countryFour},
  { name: "Angola", dialCode: "+244", flag: countrySix},
  { name: "Andorra", dialCode: "+376", flag: countryFive},
  // Add other countries similarly
];

const CountryDropdown = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-4">Select Country</h2>
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>
      <ul className="tw-max-h-64 tw-overflow-y-scroll">
        {filteredCountries?.map((country) => (
          <li key={country.name} className="tw-flex tw-items-center tw-mb-2 tw-p-2 tw-border-b tw-border-gray-200">
            <img src={country.flag} alt={country.name} className="tw-w-6 tw-h-4 tw-mr-2"/>
            {/* {country.flag} */}
            <span>{country.name} ({country.dialCode})</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CountryDropdown;
