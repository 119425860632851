import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';

import { search as searchDataLeaks } from 'store/slices/search/database/dataLeaks';

const SSLlogs = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    list: sslLogs,
    sort,
    size,
    length,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.sslLogs);

  const setCurrentPage = (page) => {
    dispatch(
      searchDataLeaks({
        query: searchTerm,
        page: page - 1,
        progress: false,
      }),
    );
  };

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: defaultRenderer,
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      render: defaultRenderer,
    },
    {
      Header: 'Issuer',
      accessor: 'issuer',
      render: defaultRenderer,
    },
    {
      Header: 'Validity',
      accessor: 'validity',
      render: (_, record) => (
        <span className=" tw-text-[#44464A]">
          {record.notBefore
            ? moment(record.notBefore).format('YYYY-MM-DD')
            : ''}{' '}
          —{' '}
          {record.notAfter ? moment(record.notAfter).format('YYYY-MM-DD') : ''}
        </span>
      ),
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'certificate', sort, size, length, searchTerm });

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-4 tw-relative">
            <ExportButton
              onChange={handleExportOptionChange}
              isLoading={exporting}
              disabled={!canExport}
            />
          </div>
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <div className="tw-relative">
          <Table
            data={sslLogs}
            columns={columns}
            selectable={true}
            onRowSelection={handleRowSelection}
          />
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p className="tw-my-4">
                Showing ssl logs:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>

            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SSLlogs;
