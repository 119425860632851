import React, { useRef, useState } from "react";
import CustomTable from "../common/table/table.component";
import Results from "../ransomware-feed-component/components/results/results.component";
import { EXPOSE_ATTACK } from "./exposeAttack.constant";
import { TbArrowBackUp } from "react-icons/tb";
import Heading from "../../common/components/heading/Heading";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import { ArrowDownLight, Export, SearchIcon } from "../../common/Icons";
import scan from "../assets/svgs/scan.svg";
import alert from "../assets/svgs/alert.svg";
import { CyberDetection } from "./components/cyber-detecion/cyber-detection.component";
import CustomSearch from "../../common/components/CustomSearch/CustomSearch";
import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import NoResult from "../../common/components/NoResult/NoResult";
import ExportOptions from "../../common/components/Export/ExportOptions";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import ExportButton from "../../common/components/Export/ExportButton";

export default function ExposeAttackComponent() {
  const [isShow, setIsShow] = useState();
  const [isInvestigating, setIsInvestigating] = useState(false);
  const [isShowResults, setIsShowResults] = useState(false);
  const [exportDropdown, setExportDropdown] = useState(false); // State to control the dropdown visibility
  const exportDropdownRef = useRef(null);
  useOutsideClick(exportDropdownRef, () => setExportDropdown(false));
  const toggleExport = (event) => {
    event.stopPropagation(); // Prevent the click from triggering the outside click handler
    setExportDropdown((prev) => !prev); // Toggle the dropdown open state
  };
  const handleInvestigate = () => {
    setIsInvestigating(true);
  };
  const handleSeeResults = () => {
    setIsShowResults(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const Modal = ({ isOpen, handleClose }) => {
    if (!isOpen) return null;
    const [domains, setDomains] = useState([
      { name: "New Server Appears", checked: true },
      { name: "New Server Ports", checked: false },
      { name: "New Server Appears", checked: false },
      { name: "New Server Ports", checked: true },
    ]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleCheckboxChange = (index) => {
      const newDomains = [...domains];
      newDomains[index].checked = !newDomains[index].checked;
      setDomains(newDomains);
    };

    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };

    const filteredDomains = domains.filter((domain) =>
      domain.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-50">
        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-[450px] ">
          <h2 className="tw-text-lg tw-font-bold tw-p-4">Setup Alerts</h2>
          <hr></hr>
          <div className=" ">
            <h2 className="tw-text-lg tw-font-normal tw-mb-4 tw-px-4 tw-pt-4">
              Domains
            </h2>

            {/* Search Input */}
            <div className="tw-relative tw-mb-4 tw-px-4">
              <input
                type="text"
                placeholder="Domain.com"
                value={searchTerm}
                onChange={handleSearch}
                className="tw-w-full tw-pl-4 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
              />
            </div>
            {/* Recent Added List */}
            <div className="tw-h-40 tw-overflow-y-auto">
              {filteredDomains.map((domain, index) => (
                <label
                  key={index}
                  className="tw-flex tw-items-center tw-mb-2 tw-ml-4"
                >
                  <input
                    type="checkbox"
                    checked={domain.checked}
                    onChange={() => handleCheckboxChange(index)}
                    className="tw-mr-2 tw-h-5 tw-my-1 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                  />
                  <span className="tw-text-sm tw-text-gray-700">
                    {domain.name}
                  </span>
                </label>
              ))}
            </div>
            <hr></hr>
            {/* Apply/Cancel Buttons */}
            <div className="tw-flex tw-mt-2 tw-ml-4 tw-gap-2 tw-mb-2">
              <button
                onClick={handleClose}
                className="tw-bg-black tw-text-white tw-px-8 tw-py-2 tw-rounded-md tw-font-medium hover:tw-bg-gray-800"
              >
                Save
              </button>
              <button
                onClick={handleClose}
                className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md tw-font-medium hover:tw-bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    { Header: "Domain", accessor: "domain" },
    { Header: "IP Address", accessor: "ipAddress" },
    {
      Header: "Matadata",
      accessor: "matadata",
      render: (_, record) => {
        return (
          <>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Reverse DNS</span>:{" "}
              {record.matadata.ReverseDNS}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">AS</span>: {record.matadata.AS}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Provicer</span>:{" "}
              {record.matadata.Provicer}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Country</span>:{" "}
              {record.matadata.Country}
            </p>
          </>
        );
      },
    },
    {
      Header: "Shodan/Zoomeye",
      accessor: "zoomeye",
      render: (_, record) => {
        return (
          <div>
            <span className="tw-font-bold tw-text-[#44464A] tw-text-xs">
              HotsNames :
            </span>
            {record.zoomeye.Hostnames.map((item, i) => {
              return <p className="tw-text-[#44464A] tw-text-xs">{item}</p>;
            })}
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">IP</span>: {record.zoomeye.ip}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Port</span>: {record.zoomeye.port}
            </p>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      domain: "Examplewebsite.com",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: [
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
        ],
        ip: "188.92.53.225",
        port: "443",
      },
    },
    {
      domain: "Examplewebsite.com",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: [
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
        ],
        ip: "188.92.53.225",
        port: "443",
      },
    },
    {
      domain: "Examplewebsite.com",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: [
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
          "tb-server-ebics.blkb.ch",
        ],
        ip: "188.92.53.225",
        port: "443",
      },
    },
    {
      domain: "azeR.com",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: ["tb-server-ebics.blkb.ch"],
        ip: "188.92.53.225",
        port: "443",
      },
    },
    {
      domain: "WeWillRockYou.rock",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: ["tb-server-ebics.blkb.ch"],
        ip: "188.92.53.225",
        port: "443",
      },
    },
    {
      domain: "MyWayOrTheHighway.com",
      ipAddress: "188.92.53.225",
      matadata: {
        ReverseDNS: " xyz",
        AS: " WISSCOM",
        Provicer: "Swisscom Ltd",
        Country: "Switzerlan",
      },
      zoomeye: {
        Hostnames: ["tb-server-ebics.blkb.ch"],
        ip: "188.92.53.225",
        port: "443",
      },
    },
  ];
  const [selectedDuration, setSelectedDuration] = useState("kaduu.com");
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!isInvestigating ? (
        <>
          <div className="tw-flex tw-gap-2 brandSearch tw-mt-1 tw-pb-6">
            <InputGroup
              className="tw-border tw-border-[#D9D9D9] tw-bg-[#F5F6F8] tw-text-base tw-px-2 tw-pb-2 tw-rounded-lg input investigate tw-w-full"
              placeholder="Enter your Domain"
            />
            <CustomButton
              type="button"
              text="Search"
              textColor="tw-text-[#44464A]"
              className="tw-text-white tw-py-2 tw-px-[14px] tw-bg-dark"
              borderRadius="tw-rounded-lg"
              fontWeight="tw-font-medium"
              fontSize="tw-text-sm"
              onClick={handleInvestigate}
              icon={<SearchIcon fill="#ffffff" />}
            />
          </div>
          <div className="tw-mt-2">
            <NoResult /> {/* Assuming this is another component */}
          </div>
        </>
      ) : !isShowResults ? (
        <>
          {/* Show CyberDetection and See Results button */}
          <div>
            <CyberDetection setIsShow={setIsShowResults} />
            <div className="tw-flex tw-justify-end tw-w-full tw-pr-6 tw-py-2 tw-bg-white">
              <CustomButton
                type="button"
                text="See Results"
                textColor="tw-text-[#44464A]"
                className="tw-text-white tw-py-2 tw-px-[14px] tw-bg-dark"
                borderRadius="tw-rounded-lg"
                fontWeight="tw-font-medium"
                fontSize="tw-text-sm"
                onClick={handleSeeResults}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-pt-4 tw-px-6 tw-mt-4">
            <div
              className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer "
              onClick={() => setIsShow((show) => !show)}
            >
              <TbArrowBackUp className="tw-mb-4 tw-text-xl" />
              <Heading size="h2" text="Infrastructure Exposure" />
            </div>
          </div>

          <div className="tw-bg-white tw-rounded-lg tw-flex tw-gap-3 tw-items-center tw-flex-col sm:tw-flex-row tw-justify-between tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
            <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
              <div className="tw-flex tw-gap-3 ">
                <img src={alert} alt="" />
                {/* <Heading size="h6" text="New Scan" /> */}
                <p className="tw-font-semibold">New Scan</p>
              </div>
              <p className="tw-text-sm tw-text-[#344054] tw-pt-1">
                Start a new infrastructure exposure scan
              </p>
            </div>
            <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
              <InputGroup
                className="tw-border tw-border-[#DFE0E3] tw-text-base tw-w-[440px] tw-px-2 tw-py-2 tw-rounded-lg input"
                placeholder="Domain.com"
              />
              <CustomButton
                type="button"
                text="Scan"
                textColor="tw-text-white"
                bgColor="tw-bg-dark"
                fontSize="tw-text-sm"
                fontWeight="tw-font-medium"
                className="tw-border-dark tw-border tw-py-2 tw-px-[14px]"
                borderRadius="tw-rounded-lg"
              />
            </div>
          </div>

          <div className="tw-bg-white tw-rounded-lg tw-flex-col sm:tw-flex-row tw-flex tw-gap-3 tw-items-center tw-justify-between tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
            <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
              <div className="tw-flex tw-gap-3">
                <img src={scan} alt="" />
                {/* <Heading size="h6" text="Setup Alerts" /> */}
                <p className="tw-font-semibold">Setup Alerts</p>
              </div>
              <p className="tw-text-sm tw-text-[#344054] tw-pt-1">
                Receive alerts when a new server appears
              </p>
            </div>
            <div className="tw-flex tw-gap-3">
              <CustomButton
                type="button"
                text="Setup Alerts"
                textColor="tw-text-white"
                bgColor="tw-bg-dark"
                fontSize="tw-text-sm"
                fontWeight="tw-font-medium"
                className="tw-border-dark tw-border tw-py-2 tw-px-[14px]"
                borderRadius="tw-rounded-lg"
                onClick={handleOpenModal}
              />
            </div>
          </div>

          <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-flex-col sm:tw-flex-row">
              <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
                <Heading size="h4" text="Infrastructure Exposure" />
                <div className="tw-flex tw-items-center tw-gap-1">
                  <span className="tw-text-[#263238] tw-text-base">
                    <button
                      className="tw-relative tw-flex tw-items-center"
                      onClick={() => setOpen((prev) => !prev)}
                    >
                      {selectedDuration}{" "}
                      <span className="tw-ml-2">
                        <ArrowDownLight />
                      </span>
                      {open && (
                        <div className="tw-absolute tw-w-36 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 tw-right-0 tw-top-6">
                          <ul className="tw-text-left">
                            <li
                              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                              onClick={() => setSelectedDuration("30")}
                            >
                              Kaduu.com
                            </li>
                            <li
                              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                              onClick={() => setSelectedDuration("6")}
                            >
                              Kaduu.com
                            </li>
                            <li
                              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                              onClick={() => setSelectedDuration("12")}
                            >
                              Kaduu.com
                            </li>
                          </ul>
                        </div>
                      )}
                    </button>
                  </span>
                </div>
              </div>
              <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row">
                <div className="tw-border tw-border-[#DFE0E3] tw-text-base tw-px-2 tw-w-[416px] tw-rounded-lg input">
                  <CustomSearch placeholder="Search" inputName="search" />
                </div>

                <ExportButton />
              </div>
            </div>
            <div className="tw-mb-4">
              <Results data={EXPOSE_ATTACK} />
            </div>
            <CustomTable data={data} columns={columns} selectable={true} />
            <Modal isOpen={isModalOpen} handleClose={handleCloseModal} />
          </div>
        </>
      )}
    </div>
  );
}
