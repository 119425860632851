import React, { useState } from "react";

const CompanySettings = () => {
  const [formData, setFormData] = useState({
    domain: "",
    size: "",
    country: "",
    industry: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
  };

  return (
    <div className="tw-max-w-sm tw-mx-auto tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-md">
      <div className="tw-text-base tw-font-bold tw-text-gray-900 tw-mb-2">
        Company Settings
      </div>
      <form onSubmit={handleSubmit}>
        <div className="tw-mb-4">
          <label
            htmlFor="domain"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Company domain
          </label>
          <input
            type="text"
            name="domain"
            id="domain"
            value={formData.domain}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
            placeholder="Company domain"
          />
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="size"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Company size
          </label>
          <input
            type="text"
            name="size"
            id="size"
            value={formData.size}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
            placeholder="Company size"
          />
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="country"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Country
          </label>
          <select
            name="country"
            id="country"
            value={formData.country}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
          >
            <option value="" disabled>
              Select
            </option>
            <option value="US">United States</option>
            <option value="UK">United Kingdom</option>
            <option value="IN">India</option>
            {/* Add more country options as needed */}
          </select>
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="industry"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Industry Type
          </label>
          <select
            name="industry"
            id="industry"
            value={formData.industry}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
          >
            <option value="" disabled>
              Select
            </option>
            <option value="tech">Technology</option>
            <option value="finance">Finance</option>
            <option value="healthcare">Healthcare</option>
            {/* Add more industry options as needed */}
          </select>
        </div>

        <div className="tw-flex tw-justify-end">
          <button
            type="submit"
            className="tw-bg-yellow-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm hover:tw-bg-yellow-600 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-yellow-500"
          >
            Apply
          </button>
          <button
            type="button"
            className="tw-ml-2 tw-border tw-font-medium tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm hover:tw-bg-gray-400 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-gray-300"
            onClick={() =>
              setFormData({ domain: "", size: "", country: "", industry: "" })
            }
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanySettings;
