import React from "react";
import RansomwareComponents from "../../components/ransomware-feed-component/ransomware.component";
import Heading from "../../common/components/heading/Heading";
import CustomTabs from "../tabs";
import DomainSpoofing from "../../components/domain-spoofing-detection/domainSpofing.component";
import ExposeAttackComponent from "../../components/expose-attack-surface/expose-attack.component";

export default function RansomwareFeedPage() {
  return (
    <>
      <Heading size="h3" text="Cyber Threat Detection" />

      <div className="tabs-sec mt-2">
        <CustomTabs>
          <div label="Ransomware Feed">
            <RansomwareComponents />
          </div>
          <div label="Domain Spoofing Detection">
            <DomainSpoofing />
          </div>
          <div label="Exposed Attack Surface Detection">
            <ExposeAttackComponent />
          </div>
        </CustomTabs>
      </div>
    </>
  );
}
