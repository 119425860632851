import React, { useRef, useState } from "react";
import {
  ArrowUpSide,
  InfoIcon,
  Setting,
  ArrowDown,
  ArrowBack,
} from "../../../common/Icons";
import ReactApexChart from "react-apexcharts";
import DomainsIncluded from "./DomainsIncluded";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import DomainDropdown from "./DomainDropdown";
import CustomTable from "../../common/table/table.component";
import { Tooltip } from "react-tooltip";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";

function DarkNet() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [open, setOpen] = useState(false);
  const settingsDropdownRef = useRef(null);
  const [isDropdownOpenSetting, setDropdownOpenSetting] = useState(false);
  const settingsDropdownRefSetting = useRef(null);

  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  useOutsideClick(settingsDropdownRefSetting, () =>
    setDropdownOpenSetting(false)
  );

  const [view, setView] = useState("main"); 

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const toggleDropdownSetting = (event) => {
    event.stopPropagation();
    setDropdownOpenSetting((prev) => !prev);
  };

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    fill: {
      type: "solid",
      colors: ["#ECC551"],
    },
    colors: ["#ECC551"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const chartSeries = [
    {
      name: "Risk Score",
      data: [40, 60, 75, 60, 80, 95, 85],
    },
  ];
  return (
    <div className="tw-p-4 tw-bg-white tw-border tw-rounded-lg tw-shadow tw-h-full">
      {view === "main" && (
        <>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-1">
              <h2 className="tw-text-xl tw-font-bold">DARKNET</h2>
              <div className="my-anchor-elementsa">
                <InfoIcon />
              </div>
              <Tooltip
                anchorSelect=".my-anchor-elementsa"
                place="right"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  width: "600px",
                }}
              >
                The Darknet Risk Score quantifies the security threat to your
                organization with a score<br></br> between 1 and 100, where 100
                represents the highest risk.<br></br>This score is derived from
                several key factors: the number of data leaks associated{" "}
                <br></br>with your company's domain, the types and severity of
                these leaks—ranging from <br></br> generic collections to those
                specifically targeting your infrastructure,and the presence{" "}
                <br></br> of your organization's data in hacker forums<br></br>
                .Additionally, we conduct an infrastructure risk analysis that
                includes checks for open <br></br>ports and known
                vulnerabilities (CVEs), and assess the security of passwords
                that <br></br>have been exposed in data leaks
              </Tooltip>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-relative">
              <div
                onClick={toggleDropdownSetting}
                className="tw-cursor-pointer"
              >
                <Setting />
              </div>
              {isDropdownOpenSetting && (
                <div
                  ref={settingsDropdownRefSetting}
                  className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                >
                  <DomainsIncluded />
                </div>
              )}
              <div onClick={toggleDropdown} className="tw-cursor-pointer">
                <div className="tw-w-full tw-py-1.5 tw-gap-2 tw-px-2  tw-mr-1   tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-yellow-200]">
                  Domain <ArrowDown />
                </div>
                {isDropdownOpen && (
                  <div
                    ref={settingsDropdownRef}
                    className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                  >
                    <DomainDropdown />
                  </div>
                )}
              </div>
            </div>
          </div>
          <p>Risk Score</p>
          <div className="tw-flex tw-justify-between">
            <div>
              <p className="tw-text-base tw-font-medium tw-text-[#00B6FF]">
                atlanta-health-insurance.com
              </p>
              <p className="tw-text-sm tw-font-normal">
                Min. Risk: <b>0</b> Max. Risk: <b>100</b>
              </p>
              <div
                className="tw-flex tw-items-center tw-gap-2"
                onClick={() => setView("details")}
              >
                <p className="tw-text-base tw-font-medium tw-cursor-pointer">
                  How it works
                </p>
                <ArrowUpSide />
              </div>
            </div>
            <p className="tw-text-2xl md:tw-text-5xl lg:tw-text-7xl tw-font-bold">
              65
            </p>
          </div>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={230}
          />
          <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-1 mt-2">
              <span className="tw-text-[#AFABAB] tw-text-sm">Year:</span>
              <span className="tw-font-semibold tw-text-black tw-text-sm tw-flex tw-items-center">
                {selectedYear}{" "}
                <button
                  className="tw-relative tw-ml-2"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  <ArrowDown />
                  {open && (
                    <div className="tw-absolute tw-w-28 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 tw-right-0 tw-top-4">
                      <ul className="">
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2022")}
                        >
                          2022
                        </li>
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2023")}
                        >
                          2023
                        </li>
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2024")}
                        >
                          2024
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </span>
            </div>
          </div>
        </>
      )}
      {view === "details" && (
        <>
          <div
            className="tw-flex tw-gap-[11px]"
            onClick={() => setView("main")}
          >
            <div className="tw-cursor-pointer">
              <ArrowBack />
            </div>
            <span className="tw-font-bold tw-text-[#263238]">
              Details Darknet Risk Score
            </span>
          </div>
          <p className="tw-pl-8">atlanta-health-insurance.com</p>
          <div className="tw-overflow-x-auto tw-shadow-lg tw-max-w-4xl tw-mx-auto ">
            <table className="tw-min-w-full tw-leading-normal">
              <thead>
                <tr>
                  <th className="tw-px-5 tw-font-semibold tw-py-3 tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold  tw-uppercase tracking-wider">
                    Title
                  </th>
                  <th className="tw-px-5 tw-py-3 tw-font-semibold tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold  tw-uppercase tracking-wider">
                    Your Value
                  </th>
                  <th className="tw-px-5 tw-py-3 tw-font-semibold tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold  tw-uppercase tracking-wider">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Number of leaks vs Organization size
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    3.5:1:1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    65
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Recent versus historical leak comparison
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    5:1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    45
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Sale of botnet logs on darknet markets
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    23
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Number of leaks vs Organization size
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    3.5:1:1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    65
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Recent versus historical leak comparison
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    2.4:1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    45
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Sale of botnet logs on darknet markets
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    23
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Sale of botnet logs on darknet markets
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    1
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    23
                  </td>
                </tr>
                <tr>
                  <td className="tw-px-5 tw-py-2.5 tw-font-semibold tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    Total Risk Score
                  </td>
                  <td className="tw-px-5 tw-py-2.5 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm"></td>
                  <td className="tw-px-5 tw-py-2.5 tw-border-b tw-font-semibold tw-border-gray-200 tw-bg-white tw-text-sm">
                    64
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default DarkNet;
