import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/ransomware/search',
  async (
    { page, size, sort, query, progress = true },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState();

    if (_.isNil(page)) page = state.search.database.ransomware.currentPage - 1;
    if (_.isNil(size)) size = state.search.database.ransomware.size;
    if (_.isNil(sort)) sort = state.search.database.ransomware.sort;

    let progressValue = 15,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 75) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      if (progress) requestAnimationFrame(mockProgress);

      dispatch(setParams({ size, sort }));
      const response = await api.get('service/ransomware_database_search/', {
        params: {
          page,
          size,
          sort,
          query,
        },
      });
      step = 1;

      return response.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const ransomwareSlice = createSlice({
  name: 'ransomware',
  initialState: {
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const {
          number: page,
          content,
          totalElements,
          totalPages,
        } = action.payload;

        state.list = content;
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = page + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setParams, setProgress } = ransomwareSlice.actions;
export default ransomwareSlice.reducer;
