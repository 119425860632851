import React from "react";
import CustomTable from "../../common/table/table.component";
import Heading from "../../../common/components/heading/Heading";

function PasswordStrengthTable() {
  const columns = [
    { Header: "Password Strengths", accessor: "created" },
    { Header: "Statistics", accessor: "results" },
  ];

  const data = [
    {
      created: "Total number of passwords found",
      results: "5487",
    },
    {
      created: "Passwords that do not contain numbers",
      results: "752 with 34.9%",
    },
    {
      created: "Passwords that do not meet the minimum length",
      results: "3242 with 54.9%",
    },
  ];
  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Password Strength analysis" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default PasswordStrengthTable;
