import React, { useState } from "react";
import CustomTable from "../../common/table/table.component";
import Heading from "../../../common/components/heading/Heading";
import EyeIcon from "../../../common/Icons/EyeIcon";

function RiskyPassword() {
  // State to track overall password visibility
  const [arePasswordsVisible, setArePasswordsVisible] = useState(false);

  // Function to toggle visibility for all passwords
  const toggleAllPasswordsVisibility = () => {
    setArePasswordsVisible((prev) => !prev);
  };

  const columns = [
    { Header: "Account", accessor: "created" },
    {
      Header: (
        <div className="tw-flex tw-items-center tw-gap-[5px]">
          Password
          <button
            onClick={toggleAllPasswordsVisibility} 
          >
            <EyeIcon
              color="#5B5D63"
              className="eye-icon-hover cursor-pointer"
            />
          </button>
        </div>
      ),
      accessor: "password",
      Cell: ({ row }) => {
        const { password } = row.original; // Destructure to get the password from the record
        return (
          <div className="tw-flex tw-items-center tw-gap-[10px]">
            {/* Show password if visibility is toggled on, otherwise asterisks */}
            <span>{arePasswordsVisible ? "111" : "******"}</span>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      created: "babar@thrivedx.com",
      password: arePasswordsVisible ? "111111" : "***",
    },
    {
      created: "jane@thrivedx.com",
      password: arePasswordsVisible ? "password123" : "***",
    },
    {
      created: "john@thrivedx.com",
      password: arePasswordsVisible ? "qwerty" : "***",
    },
  ];

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Top Risky Passwords" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default RiskyPassword;
