import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from 'common/components/Button/Button';
import DropdownList from 'common/Icons/DropdownList';
import FunnelFilter from 'common/Icons/FunnelFilter';
import ExportButton from 'common/components/Export/ExportButton';
import CreationDropdown from 'common/components/DropDown/CreationDropdown';
import GenericDropdown from 'common/components/GenericDropdown/GenericDropdown';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';
import ReadMore from 'common/components/ReadMore/ReadMore';

import { search as searchRansomware } from 'store/slices/search/database/ransomware';

const ThreatActor = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    list: ransomwares,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.ransomware);

  const setCurrentPage = (page) => {
    dispatch(
      searchRansomware({
        query: searchTerm,
        page: page - 1,
        progress: false,
      }),
    );
  };

  // const handleChange = (_length) => {
  //   if (length !== _length) {
  //     dispatch(
  //       searchRansomware({
  //         query: searchTerm,
  //         length: _length,
  //         progress: false,
  //       }),
  //     );
  //   }
  // };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };
  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Company',
      accessor: 'company',
      render: defaultRenderer,
    },
    {
      Header: 'Threat',
      accessor: 'threat',
      render: defaultRenderer,
    },
    {
      Header: 'Published',
      accessor: 'publishedAt',
      render: defaultRenderer,
    },
    {
      Header: 'Details',
      accessor: 'description',
      render: (value, record) => {
        return (
          <>
            <ReadMore text={value} size={200} />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.url}</p>
          </>
        );
      },
    },
  ];

  const FilterFormModal = ({ showModal, closeModal }) => {
    if (!showModal) return null; // If the modal is not open, return nothing

    const [isRiskDropdownOpen, setIsRiskDropdownOpen] = useState(false);
    const handleRiskDropdown = (isOpen) => {
      setIsRiskDropdownOpen(isOpen);
    };

    return (
      <div
        className={`tw-fixed tw-right-0 tw-bottom-${isRiskDropdownOpen ? 28 : 0} tw-p-4 tw-mt-24 tw-mr-4 tw-bg-white tw-w-80 tw-shadow-lg tw-rounded-lg tw-z-50`}
      >
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-lg tw-font-semibold">Filter</h2>
          <button onClick={closeModal} className="tw-text-3xl tw-text-gray-500">
            &times;
          </button>
        </div>

        {/* Form Starts Here */}
        <form>
          {/* Start Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              From Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* End Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              End Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* Enumeration Method */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Enumeration Method"
              labelText="Enumeration Method"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Method 1',
                  label: 'Method 1',
                },
                {
                  value: 'Method 2',
                  label: 'Method 2',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Domain Condition */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor=" Domain has a"
              labelText="Domain has a"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Has',
                  label: 'Has',
                },
                {
                  value: 'Does not have',
                  label: 'Does not have',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Categories */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Categories"
              labelText="Categories"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Category 1',
                  label: 'Category 1',
                },
                {
                  value: 'Category 2',
                  label: 'Category 2',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Risk */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Risk"
              labelText="Risk"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Low',
                  label: 'Low',
                },
                {
                  value: 'Medium',
                  label: 'Medium',
                },
                {
                  value: 'High',
                  label: 'High',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
              onDropDown={handleRiskDropdown}
            />
          </div>

          {/* Buttons */}
          <div className="tw-flex tw-justify-around tw-mt-4">
            <button
              type="button"
              className="tw-bg-yellow-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-yellow-600"
              onClick={closeModal}
            >
              Apply Filter
            </button>
            <button
              type="button"
              className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
              onClick={closeModal}
            >
              Clear Filter
            </button>
          </div>
        </form>
      </div>
    );
  };

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'ransomware', size, searchTerm });

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>
        </div>

        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
          <div className={loading ? 'tw-invisible' : 'tw-visible'}>
            <p>
              Showing ransomwares:{' '}
              {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
              {currentPage * size > totalElements
                ? totalElements
                : currentPage * size}{' '}
              of {totalElements}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center md:tw-flex-row tw-gap-1 md:tw-gap-4">
            {/* <CreationDropdown defaultValue={length} onChange={handleChange} /> */}
            {/* <CustomButton
              icon={<DropdownList />}
              text="Creation time"
              className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
            /> */}
            <CustomButton
              bgColor="tw-bg-white"
              textColor="tw-text-primary"
              icon={<FunnelFilter />}
              text="Filters"
              onClick={openFilterModal}
              className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#F5F6F8]"
            />
            <ExportButton
              onChange={handleExportOptionChange}
              isLoading={exporting}
              disabled={!canExport}
            />
          </div>
        </div>
        <div className="tw-relative">
          <Table
            data={ransomwares}
            columns={columns}
            selectable={true}
            onRowSelection={handleRowSelection}
          />
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>

        <FilterFormModal
          showModal={isFilterModalOpen}
          closeModal={closeFilterModal}
        />
      </div>
    </div>
  );
};

export default ThreatActor;
