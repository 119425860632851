import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Danger from 'common/Icons/Danger';
import BellYellow from 'common/Icons/BellYellow';
import CustomButton from 'common/components/Button/Button';
import CustomTabs from './Tab';

import Leaks from './components/Leaks';
import DataBrokers from './components/DataBrokers';
import DataPastes from './components/DataPastes';
import FakeApps from './components/FakeApps';
import CreditCards from './components/CreditCards';
import CreditCardLeaks from './components/CreditCardLeaks';
import URLShorteners from './components/URLShorteners';
import LeakDetails from './components/leak-detail/LeakDetails';
import StealerLogs from './components/StealerLogs';
import OpenCloud from './components/OpenCloud';
import ThreatActor from './components/ThreatActor';
import EmailRef from './components/EmailRef';
import StealerLogDetails from './components/stealer-detail/StealerLogDetails';
import PhishingAttack from './components/PhishingAttack';
import SSLlogs from './components/SSLlogs';

const SearchResult = ({ selectedOption, setSearch, searchTerm, tabsInfo }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const _activeTab = tabsInfo.find((tab) => tab.active);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(_activeTab.id);
  const [isLeakDetails, setIsLeakDetails] = useState(false);
  const [selectedLeakId, setLeakId] = useState(null);
  const [isStealerLogDetails, setIsStealerLogDetails] = useState(false);
  const [selectedStealerLog, setStealerLog] = useState(null);

  const handleLeakDetail = (leakId) => {
    setLeakId(leakId);
    setIsLeakDetails(true);
  };

  const handleStealerLogDetail = (stealerLog) => {
    setStealerLog(stealerLog);
    setIsStealerLogDetails(true);
  };

  return (
    <>
      {isLeakDetails ? (
        <LeakDetails
          leakId={selectedLeakId}
          setIsLeakDetails={setIsLeakDetails}
        />
      ) : isStealerLogDetails ? (
        <StealerLogDetails
          stealerLog={selectedStealerLog}
          setIsStealerLogDetails={setIsStealerLogDetails}
        />
      ) : (
        <>
          {!isAuthenticated && (
            <div className="tw-flex tw-items-center tw-gap-[9.45px] tw-bg-[#fcf6e5] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
              <Danger />
              <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                You&apos;re using the anonymous leak search functionality.
                Please{' '}
                <a
                  href="/login"
                  className="tw-text-sm tw-font-semibold tw-text-[#0b0b0b] tw-leading-6 tw-underline"
                >
                  Sign up here{' '}
                </a>
                to see full, unredacted results.
              </div>
            </div>
          )}

          <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <BellYellow />
              <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                If you didn&apos;t find what you were looking for, try refining
                your search or click &lsquo;New search&rsquo; to start again.
              </div>
            </div>
            <CustomButton
              text="New Search"
              onClick={() => {
                setSearch(false);
                navigate('/search-all-databases');
              }}
              className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
            />
          </div>

          <>
            <div className="tabs-sec">
              <CustomTabs active={activeTab} onTabActived={setActiveTab}>
                <div
                  id={tabsInfo[0].id}
                  label={tabsInfo[0].label}
                  disabled={!tabsInfo[0].active}
                >
                  {selectedOption.label === 'Credit Card' ? (
                    <CreditCardLeaks
                      selectedOptionLabel={selectedOption.label}
                      searchTerm={searchTerm}
                      onLeakDetail={handleLeakDetail}
                    />
                  ) : (
                    <Leaks
                      selectedOptionLabel={selectedOption.label}
                      searchTerm={searchTerm}
                      onLeakDetail={handleLeakDetail}
                    />
                  )}
                </div>

                <div
                  id={tabsInfo[1].id}
                  label={tabsInfo[1].label}
                  disabled={!tabsInfo[1].active}
                >
                  <StealerLogs
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                    onStealerLogDetail={handleStealerLogDetail}
                  />
                </div>

                <div
                  id={tabsInfo[2].id}
                  label={tabsInfo[2].label}
                  disabled={!tabsInfo[2].active}
                >
                  <URLShorteners
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[3].id}
                  label={tabsInfo[3].label}
                  disabled={!tabsInfo[3].active}
                >
                  <OpenCloud
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[4].id}
                  label={tabsInfo[4].label}
                  disabled={!tabsInfo[4].active}
                >
                  <DataPastes
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[5].id}
                  label={tabsInfo[5].label}
                  disabled={!tabsInfo[5].active}
                >
                  <ThreatActor
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[6].id}
                  label={tabsInfo[6].label}
                  disabled={!tabsInfo[6].active}
                >
                  <DataBrokers
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[7].id}
                  label={tabsInfo[7].label}
                  disabled={!tabsInfo[7].active}
                >
                  <PhishingAttack
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[8].id}
                  label={tabsInfo[8].label}
                  disabled={!tabsInfo[8].active}
                >
                  <FakeApps
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[9].id}
                  label={tabsInfo[9].label}
                  disabled={!tabsInfo[9].active}
                >
                  <CreditCards
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[10].id}
                  label={tabsInfo[10].label}
                  disabled={!tabsInfo[10].active}
                >
                  <EmailRef
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>

                <div
                  id={tabsInfo[11].id}
                  label={tabsInfo[11].label}
                  disabled={!tabsInfo[11].active}
                >
                  <SSLlogs
                    selectedOptionLabel={selectedOption.label}
                    searchTerm={searchTerm}
                  />
                </div>
              </CustomTabs>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default SearchResult;
