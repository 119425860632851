import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import CustomButton from "../../../common/components/Button/Button";

const DomainDropdown = () => {
  const [domains, setDomains] = useState([
    { name: "America" },
    { name: "Bangkok" },
    { name: "India" },
    { name: "Pakistan" },
    { name: "Bangladesh" },
    { name: "Africa" },
  ]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDomains = domains.filter((domain) =>
    domain.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-4">Select Company</h2>

      {/* Search Input */}
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>
      {/* Recent Added List */}
      <h3 className="tw-text-sm tw-font-medium tw-mb-2">Recent Selected</h3>
      <div className="tw-h-40 tw-overflow-y-auto">
        {filteredDomains.map((domain, index) => (
          <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-py-1.5">
            <span className="tw-text-sm tw-text-gray-700">{domain.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default DomainDropdown;
