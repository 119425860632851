import React from "react";
import CustomTable from "../../common/table/table.component";
import Heading from "../../../common/components/heading/Heading";

function Infrastructure() {
  const columns = [
    { Header: "Affected Systems from Stealer Logs", accessor: "created" },
    { Header: "Results", accessor: "results" },
  ];

  const data = [
    {
      created: "emdcustomersection.kontron.com",
      results: "111111",
    },
    {
      created: "us.kontron.com",
      results: "111111",
    },
    {
      created: "worldcup.kontron.com",
      results: "111111",
    },
  ];
  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Leaks from your own Infrastructure" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default Infrastructure;
