import React, { useState } from 'react';

export default function CustomTabs({ children, tabList }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <div className="tabs tw-overflow-auto md:tw-overflow-hidden">
        <div
          className={
            tabList
              ? 'tab-list2 tw-w-fit tw-flex tw-gap-4 tw-flex-nowrap md:tw-flex-wrap'
              : 'tab-list tw-w-fit tw-flex tw-gap-4 tw-flex-nowrap md:tw-flex-wrap'
          }
        >
          {children.map((child, i) => (
            <Tab
              key={i.toString()}
              label={child.props.label}
              onClick={() => setActiveIndex(i)}
              isActive={i === activeIndex}
              tabList={tabList}
            />
          ))}
        </div>
      </div>

      {children?.length && (
        <div className="tab-content">
          {children.map((child, i) => (i === activeIndex ? child : null))}
        </div>
      )}
    </>
  );
}

const Tab = ({ label, onClick, isActive, tabList }) => {
  return (
    <>
      {tabList ? (
        <button
          className={`tab ${
            isActive
              ? 'tw-font-bold tw-pb-3 tabActiveList tw-whitespace-nowrap tw-text-sm'
              : 'tw-text-[#0B0B0B] tw-pb-3 tw-whitespace-nowrap tw-text-sm'
          }`}
          onClick={onClick}
        >
          {label}
        </button>
      ) : (
        <button
          className={`tab ${
            isActive
              ? 'tw-font-bold tw-pb-3 tabActive tw-whitespace-nowrap tw-text-sm md:tw-text-base'
              : 'tw-text-[#0B0B0B] md:tw-text-base tw-pb-3 defaultTab tw-whitespace-nowrap tw-text-sm'
          }`}
          onClick={onClick}
        >
          {label}
        </button>
      )}
    </>
  );
};
