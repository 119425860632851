import React, { useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ArrowDown, Setting } from "../../../common/Icons";
import CompanySettings from "./CompanySettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import CountryDropdown from "./CountryDropDown";

function BenchmarkChart() {
  // State to manage which tab is active
  const [activeTab, setActiveTab] = useState("country");
  // Common chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      background: "#FFFFFF",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        barHeight: "40%",
        distributed: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      categories: ["Your Organization", "Switzerland","America"],
    },
    fill: {
      colors: ["#F9EDC9", "#BD9E41", "#ECC551"],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent']
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Tooltip content for static data
        return (
          '<div class="custom-tooltip tw-p-3 tw-bg-[#F9F9F9] tw-rounded-lg">' +
          '<div class="tw-font-semibold tw-text-gray-800 tw-text-base">Your Organization</div>' +
          '<div class="tw-text-gray-600 tw-text-sm">100.00/100</div>' +
          '<div class="tw-font-semibold tw-text-gray-800 tw-mt-2 tw-text-base">Switzerland</div>' +
          '<div class="tw-text-gray-600">100.00/100 (mode)</div>' +
          '<div class="tw-text-gray-600">100.00/100 (median)</div>' +
          '<div class="tw-font-semibold tw-mt-2 tw-text-base">Legent</div>' +
          '<div class="tw-flex tw-items-center tw-gap-2">' +
          '<span class="tw-w-3 tw-h-3 tw-bg-[#FCE0B6] tw-rounded-full"></span>' +
          '<span class="tw-text-gray-600 tw-text-sm">Mean</span>' +
          "</div>" +
          '<div class="tw-flex tw-items-center tw-gap-2 ">' +
          '<span class="tw-w-3 tw-h-3 tw-bg-[#925C0C] tw-rounded-full"></span>' +
          '<span class="tw-text-gray-600 tw-text-sm">Median</span>' +
          "</div>" +
          '<div class="tw-flex tw-items-center tw-gap-2 ">' +
          '<span class="tw-w-3 tw-h-3 tw-bg-[#BD9E41] tw-rounded-full"></span>' +
          '<span class="tw-text-gray-600 tw-text-sm">Your Company</span>' +
          "</div>" +
          "</div>"
        );
      },
    },
    grid: {
      borderColor: "#ECECEC",
    },
    legend: {
      show: false,
    },
  };

  const seriesData = {
    country: [
      { name: "Mean", data: [80, 90,45] },
      { name: "Median", data: [86, 75,80] },
      { name: "Your Company", data: [100, 40,60] },
    ],
    industry: [
      { name: "Mean", data: [50, 95,57] },
      { name: "Median", data: [85, 75,90] },
      { name: "Your Company", data: [92, 85,56] },
    ],
    competitor: [
      { name: "Mean", data: [78, 94,70] },
      { name: "Median", data: [45, 78,90] },
      { name: "Your Company", data: [76, 89,40] },
    ],
   
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const settingsDropdownRef = useRef(null);
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const [isDropdownOpenSetting, setDropdownOpenSetting] = useState(false);
  const settingsDropdownRefSetting = useRef(null);
  const toggleDropdownSetting = (event) => {
    event.stopPropagation();
    setDropdownOpenSetting((prev) => !prev);
  };

  return (
    <div className="tw-bg-white tw-border tw-shadow tw-rounded-lg tw-p-4">
      <h4 className="tw-text-base tw-text-dark tw-font-bold">Benchmarks</h4>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-mt-4">
        <div className=" tw-border-b-4 tw-border-gray-300 tw-justify-start">
          {["country", "industry", "competitor"]?.map((tab) => (
            <button
              key={tab}
              className={`tw-flex-1 tw-pb-4 -tw-mb-2 tw-px-4 tw-text-base tw-font-medium tw-text-center tw-transition tw-duration-150 tw-ease-in-out ${
                activeTab === tab
                  ? "tw-text-black tw-border-b-4 tw-border-yellow-500 "
                  : "tw-text-gray-500 tw-bg-transparent tw-hover:tw-bg-gray-100"
              }`}
              onClick={() => setActiveTab(tab)}
              style={{ textTransform: "capitalize" }}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="tw-flex tw-relative tw-flex-col md:tw-flex-row tw-justify-end md:tw-gap-1 lg:tw-gap-4 tw-mt-2">
          <div>
            <div onClick={toggleDropdown} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <CompanySettings />
              </div>
            )}
          </div>

          <div
            onClick={toggleDropdownSetting}
            className="tw-w-full tw-py-1 tw-px-2 tw-gap-2  tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-yellow-200]"
          >
            Switzerland <ArrowDown />
          </div>
          {isDropdownOpenSetting && (
            <div
              ref={settingsDropdownRefSetting}
              className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
            >
              <CountryDropdown />
            </div>
          )}
        </div>
      </div>

      <ReactApexChart
        options={chartOptions}
        series={seriesData[activeTab]}
        type="bar"
        height={300}
      />
    </div>
  );
}

export default BenchmarkChart;
