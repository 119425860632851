import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table/table.component';

import { capitalizeFirstLetter, truncateString } from 'utils/global';

const DataBrokers = ({ selectedOptionLabel, searchTerm }) => {
  const { list, totalElements } = useSelector(
    (state) => state.search.database.dataBrokers,
  );

  const size = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      Header: 'Forum | Seller',
      accessor: 'source',
      render: (value, record) => (
        <div className="tw-text-[#44464A]">
          <p>{value}</p>
          <p>{extractSeller(record.content)}</p>
        </div>
      ),
    },
    {
      Header: 'Content Extract',
      accessor: 'content',
      render: (value, _, rowIndex) => {
        const short = truncateString(value);

        return (
          <>
            <span className="tw-text-[#44464A]" id={`row-${rowIndex}`}>
              {short}
            </span>
            {short.length < value.length && (
              <Tooltip
                anchorSelect={`#row-${rowIndex}`}
                place="top-end"
                border="1px solid #eee"
                opacity={1}
                style={{
                  backgroundColor: '#ECC551',
                  color: 'black',
                  borderRadius: 10,
                  padding: 20,
                  width: 450,
                  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                {value}
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      Header: 'Scraped Date',
      accessor: 'date_scrapped',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Price',
      render: (_, record) => {
        return (
          <span className=" tw-text-[#44464A]">
            {extractPrice(record.content)}
          </span>
        );
      },
    },
    {
      Header: 'URL',
      render: (_, record) => {
        return (
          <span className=" tw-text-[#44464A]">
            {extractURL(record.content)}
          </span>
        );
      },
    },
    {
      Header: 'Data Types',
      accessor: 'meta',
      render: (value) => (
        <span className=" tw-text-[#44464A]">
          {capitalizeFirstLetter(value.replace('section: ', ''))}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>

          <ExportButton />
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <Table
          data={list}
          columns={columns}
          rowsPerPage={size}
          paginatorLabel="data"
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default DataBrokers;

const extractSeller = (str) => {
  // Regular expression to match both formats: seller: xxx or "seller" : "xxx"
  const regex = /seller\s*:\s*"?([\w\s]+)"?/;

  const match = str.match(regex);
  return match ? match[1] : null;
};

const extractPrice = (str) => {
  // Regular expression to match both formats: price: xxx or "price" : "xxx"
  const regex = /price\s*:\s*(\d+(\.\d+)?|"(\d+(\.\d+)?)")/;

  const match = str.match(regex);
  if (match) {
    const priceString = match[3] ? match[3] : match[1];
    return parseFloat(priceString).toFixed(2);
  } else {
    return '—';
  }
};

const extractURL = (str) => {
  // Regular expression to match both formats: url: xxx or "url" : "xxx"
  const regex = /url\s*:\s*"?([\w:/?.&%=-]+)"?/;

  const match = str.match(regex);
  return match ? match[1] : '—';
};
