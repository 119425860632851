import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/phishingAttack/search',
  async ({ query }, { dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 50) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(600);
          requestAnimationFrame(mockProgress);
        } else if (step === 2 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        } else if (step === 3 && progressValue < 100) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/create_phishing_search_request/',
        {
          search_term: query,
        },
      );

      progressValue = 10;
      step = 1;
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/get_phishing_search_request/${requestResponse.data.id}/`,
        );

        if (statusResponse.data.status === 'finished') break;
        await delay(2500);
      }

      progressValue = 50;
      step = 2;
      requestAnimationFrame(mockProgress);
      const resultResponse = await api.get(
        'service/list_phishing_search_result/',
        {
          params: { searchrequest: requestResponse.data.id },
        },
      );

      progressValue = 95;
      step = 3;
      requestAnimationFrame(mockProgress);
      await api.delete(
        `service/delete_phishing_search_request/${requestResponse.data.id}/`,
      );

      return resultResponse.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const phishingAttackSlice = createSlice({
  name: 'phishingAttack',
  initialState: {
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setProgress } = phishingAttackSlice.actions;
export default phishingAttackSlice.reducer;
