import React, { useRef, useState } from "react";
import { Setting } from "../../../common/Icons";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import CustomBubbleChart from "../../charts/bubble_chart/bubble_chart";
import CustomSelect from "../../../common/components/Select/select";
import CompanySettings from "./CompanySettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";

function Alerts() {
  const handleSelect = (option) => {
    console.log("option:", option);
  };

  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false); 
  const settingsDropdownRef = useRef(null);

  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));

  const toggleDropdown = (event) => {
    event.stopPropagation(); 
    setDropdownOpen((prev) => !prev);
  };
  return (
    <div className="tw-p-4 tw-bg-white tw-shadow-md tw-rounded-lg">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center ">
        <div className="tw-flex  tw-items-center tw-gap-1 md:tw-gap-4">
          {/* Legend section */}
          <div className="tw-flex tw-items-center tw-gap-1 md:tw-gap-2">
            <span className="tw-bg-[#F9EDC9] tw-h-4 tw-w-4 tw-rounded-full md:tw-mr-2 tw-mr-[2px]"></span>
            Mean
          </div>
          <div className="tw-flex tw-items-center">
            <span className="tw-bg-[#BD9E41] tw-h-4 tw-w-4 tw-rounded-full md:tw-mr-2 tw-mr-[2px]"></span>
            Median
          </div>
          <div className="tw-flex tw-items-center">
            <span className="tw-bg-[#ECC551] tw-h-4 tw-w-4 tw-rounded-full md:tw-mr-2 tw-mr-[2px]"></span>
            Your Company
          </div>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-4 ">
          {/* Buttons and dropdown */}
          <button
            className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded"
            onClick={() =>
              navigate("/AlertingRules", { state: { isNewAlertingOpen: true } })
            }
          >
            Setup Alerts
          </button>
          <div className="tw-relative">
            <div onClick={toggleDropdown} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <CompanySettings />
              </div>
            )}
          </div>

          <GenericDropdown
            options={options}
            defaultText="Choose an option"
            staticFirstOption={true}
          />
        </div>
      </div>
      <CustomBubbleChart />
    </div>
  );
}

export default Alerts;
const options = ["Date Range", "Last Year", "Last 6 Month", "All Time"];
