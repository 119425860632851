import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import useExport from 'components/common/hooks/use-export';

import { search as searchStealerLogs } from 'store/slices/search/database/stealerLogs';
import { getCountryNameFromCode } from 'utils/global';

const StealerLogs = ({
  selectedOptionLabel,
  searchTerm,
  onStealerLogDetail,
}) => {
  const dispatch = useDispatch();

  const {
    list: stealerLogs,
    sort,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.stealerLogs);

  const setCurrentPage = (page) => {
    dispatch(
      searchStealerLogs({
        query: searchTerm,
        page: page - 1,
        progress: false,
      }),
    );
  };

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A]">
            {moment(value).format('YYYY-MM-DD')}
          </span>
        );
      },
    },
    {
      Header: 'IP',
      accessor: 'ip',
      render: (value, record) => {
        return (
          <span
            onClick={() => {
              if (onStealerLogDetail) onStealerLogDetail(record);
            }}
            className="tw-text-[#00B6FF] tw-cursor-pointer"
          >
            {value}
          </span>
        );
      },
    },
    {
      Header: 'Bot',
      accessor: 'botName',
      render: (value) => {
        return (
          <span className="tw-bg-[#F9FAFB] tw-border tw-border-[#EAECF0] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#344054]">
            {value}
          </span>
        );
      },
    },
    {
      Header: 'Version',
      accessor: 'botVersion',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A]">
            {_.isNil(value) ? 'N/A' : value}
          </span>
        );
      },
    },
    {
      Header: 'Country',
      accessor: 'country',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A]">
            {getCountryNameFromCode(value)}
          </span>
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      render: (value) => {
        return <span className="tw-text-[#44464A]">{value}</span>;
      },
    },
    {
      Header: 'Data',
      accessor: 'data',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-justify-between">
              <div className="tw-flex tw-gap-2">
                <span className="tw-text-[#888C95]">URL</span>
                <span className="tw-text-[#00A4E6]">{record.url}</span>
              </div>
              <div className="tw-flex tw-gap-2">
                <span className="tw-text-[#888C95]">Name</span>
                <span>{record.name}</span>
              </div>
              <div className="tw-flex tw-gap-2">
                <span className="tw-text-[#888C95]">Value</span>
                <span>{record.value}</span>
              </div>
              <div className="tw-flex tw-gap-2">
                <span className="tw-text-[#888C95]">Time</span>
                <span>{record.time}</span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'bot_record', sort, size, length, searchTerm });

  return (
    <div>
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </p>

          <ExportButton
            onChange={handleExportOptionChange}
            isLoading={exporting}
            disabled={!canExport}
          />
        </div>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>

        <div className="tw-relative">
          <Table
            data={stealerLogs}
            columns={columns}
            selectable={true}
            onRowSelection={handleRowSelection}
          />

          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p className="tw-my-4">
                Showing stealer logs:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </div>

          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StealerLogs;
